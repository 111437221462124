
.hak-image-box {
    display: flex;
    flex-flow: column;
    position: absolute;
    width: 60%;
    height: 80%;
    top: 10%;
    left: 20%;
    right: 20%;
    bottom: 10%;
    z-index: 2;
}

.zoom-icon{
  width:20px;
}
  
.hak-image-box .hak-image-row.header {
	flex: 0 1 auto;
}
  
.hak-image-box .hak-image-row.content {
	/* flex: 1 1 auto; */
	overflow: scroll;  
	background-color: white;
}

.hak-image-row.content {
	position: relative;
}

.hak-image-row.content div {
	position: absolute;
	height: 100px;
	width: 300px;
}
  
.hak-image-box .hak-image-row.footer {
	background-color: rgb(158, 165, 173);
	flex: 0 1 20px;
}
.hak-pdf-footer {
	background-color: rgb(158, 165, 173);
	flex: 0 1 20px;
	position: fixed;
	bottom: 0;
	width: 100%;
}
.hak-image-box.hak-modal {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

.hak-modal-folder {
	position: absolute;
	top: 1%;
	left: 2%;
	right: 2%;
	bottom: 2%;
	z-index: 1;
	margin-bottom: 45px;
	background-color: rgb(158, 165, 173);
	border: 1px solid rgb(69, 76, 82);
}

.hak-modal-video { 
	overflow: scroll;
	position: absolute;
	top: 7%;
	left: 0%;
	right: 0%;
	bottom: 7%;
	z-index: 1;
	border-radius: 5px;
	background-color: rgb(158, 165, 173);
	border: 4px solid rgb(69, 76, 82);
	box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
	-webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
	-moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
}

.hak-modal-video-contact { 
	overflow: scroll;
	position: absolute;
	top: 7%;
	left: 0%;
	right: 5%;
	bottom: 0%;
	z-index: 1;
	border-radius: 5px;
	background-color: rgb(158, 165, 173);
	border: 4px solid rgb(69, 76, 82);
	box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
	-webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
	-moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
}

.hak-modal-password { 
	/* width: 400px; */
	/* height: 250px; */
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	z-index: 1;
	border-radius: 5px;
	background-color: rgb(50, 51, 51);
	/* background-color: rgb(158, 165, 173); */
	/* border: 4px solid rgb(69, 76, 82); */
}

.hak-modal-audio { 
	position: absolute;
	top: 30%;
	left: 30%;
	right: 30%;
	z-index: 1;
	border-radius: 5px;
	background-color: rgb(158, 165, 173);
	border: 4px solid rgb(69, 76, 82);
}

.hak-audioplayer {
	margin-bottom: "100px";
	height: auto;
}

.hak-modal-txt { 
	position: absolute;
	top: 15%;
	left: 20%;
	right: 20%;
	border-radius: 5px;
	z-index: 1;
	background-color: rgb(158, 165, 173);
	border: 4px solid rgb(69, 76, 82);
	box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
	-webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
	-moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
}

.hak-modal:focus { outline: none; }
.hak-modal-audio:focus { outline: none; }
.hak-modal-pdf:focus { outline: none; }
.hak-modal-txt:focus { outline: none; }
.hak-modal-password:focus { outline: none; }

.hak-modal-header {
    background-color: rgb(179,179,179);
    border-radius: 6px 6px 0 0;
    height: 45px;
    color: rgb(50, 51, 51);
    justify-content: center;
    cursor: move;
}

.hak-modal-header-vumail {
	background-color: #2E3191;
	height: 35px;
	color: white;
	cursor: move;
}

.hak-form {
	padding: 3em;
	width: 80%;
}

.center  {
	display: flex;
	justify-content: center;
	align-items: center;
}

.header-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error-message {
	/* color: red; */
	color: #f3ec3b;
}
  
.hak-it-password-container {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}
  
.hak-it-password-inputBox {
	padding: 12px 20px;
	margin: 4px 4px;
	display: inline-block;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	width: 35px !important;
}

::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 7px;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: rgba(0, 0, 0, .5);
	box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.hak-content{
	border-radius: 0 0 6px 6px;
}
@media screen and (max-width:1082px) {
	.hak-modal-password, .hak-modal-audio, .hak-modal-txt, .hak-modal-pdf, .hak-modal-folder  { 
		left: 0%;
		top: 0%;
		right: 0%;
	}

	.hak-modal-folder{ 
		left: 0%;
		top: 0%;
		bottom: 0%;
		right: 0%;
	}
}

@media screen and (max-width:601px) {
  .hak-image-box  { 
    left: 0%;
    top: 0%;
    bottom: 0%;
    right: 0%;
    height:100%;
    width:100%;
	}
	.hak-zoom-icon{
		margin-bottom:10px;
	}
	.hak-image-row-footer{
		bottom:6% !important;
	}
	.hak-content{
		border-radius: 0;
	}
}
