.desktop-container {
    width: 100%;
    height: 100%;

    max-width: 100%;
    max-height: 100%;
    background-size: 22rem;
    background-repeat: no-repeat;
    background-position: center;
}

.desktop-icons-container {
    /* display: flex; */
    /* flex-direction: row; */
    /* flex-wrap: wrap; */
    margin: 1rem 4rem;
}

.desktop-icon {
    width: 6rem;
    height: 6rem;
    margin: 1.5rem 0;
    cursor: pointer;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    background-color: rgba(35, 31, 32, 255);
    height: 2.5rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
}

.footer>div:first-child {
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .desktop-container {
        background-size: 12rem;
    }
    .footer {
        height: 3rem;
        
    }
}
