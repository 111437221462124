.hak-desktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(165, 101, 45);
    color: white;
}

.hak-desktop-background {
    height: 18rem;
    width: 24rem;
    margin-bottom: 2rem;
}

.hak-desktop-pwd-input {
    width: 18rem;
    height: 3rem;
    border-radius: 0.2rem;
    border: 0;
    background-color: #ffffff;
    text-decoration: none;
    text-align: center;
}

.hak-desktop-pwd-input:focus {
    outline-offset: 0px;
    outline: none;
}

.hak-login-button {
    margin-top: 2rem;
    width: 150px;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 0.5rem;
    background-color: #dfdfde;
    border-radius: 0.5rem;
    border: 0;
}

.text-error {
    /* color: #f5adad; */
    color: #e3d237;
    font-weight: bold;
    padding-top: 10px;
}

@media screen and (max-width: 600px) {
    .hak-desktop {
        height: 100vh;
    }
    .hak-desktop-background {
        width: 18rem;
        height: 14rem;
    }
    .hak-desktop-pwd-input {
        text-align: left;
        padding: 0 20px;    
    }
    .text-error {
        padding-top: 1rem;
    }
    .hak-login-button {
        margin-top: 1rem;
    }
    .folder.customize-icon, image.customize-icon {
        display: inline-block;
        margin: auto;
    }
    li.folder.customize-icon:nth-child(2), li.image.customize-icon:nth-last-child(3) {
        margin: auto;
    }
    .desktop-icons-container {
        display: grid;
        grid-template-columns: 50% 50%;
        margin: 2rem;
    }
    li.contact.customize-icon:nth-last-child(2) {
        right: 18%;
        bottom: 5rem;
    }
}