.hak-modal {
    width: 60%;
    height: 80%;
    position: absolute;
    top: 10%;
    left: 20%;
    right: 20%;
    bottom: 10%;
    background-color: white;
    border-radius: 6px;
    box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
    -webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
    -moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
    z-index: 2;
}

.hak-modal-pdf {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    border-radius: 6px;
    background-color: rgb(158, 165, 173);
    /* border: 4px solid rgb(69, 76, 82); */
}

.hak-modal-password {
    width: 500px;
    height: 320px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 5px;
    background-color: rgb(50, 51, 51);
    /* background-color: rgb(158, 165, 173); */
    /* border: 4px solid rgb(69, 76, 82); */
}

.hak-modal-audio {
    position: absolute;
    top: 30%;
    left: 30%;
    right: 30%;
    border-radius: 5px;
    background-color: rgb(158, 165, 173);
    border: 4px solid rgb(69, 76, 82);
}

.hak-audioplayer {
    margin-bottom: "100px";
    height: auto;
}

.hak-modal-txt {
    position: absolute;
    top: 5%;
    left: 20%;
    right: 20%;
    border-radius: 5px;
    background-color: rgb(158, 165, 173);
    border: 4px solid rgb(69, 76, 82);
    box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
    -webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
    -moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.2);
}

.hak-modal-txt-overflow {
    overflow-y: scroll;
    height: 25em;
}

.hak-modal:focus {
    outline: none;
}

.hak-modal-audio:focus {
    outline: none;
}

.hak-modal-settings:focus {
    outline: none;
}

.hak-modal-pdf:focus {
    outline: none;
}

.hak-modal-txt:focus {
    outline: none;
}

.hak-modal-password:focus {
    outline: none;
}

.hak-modal-header {
    display: flex;
    align-items: center;
    background-color: rgba(179, 179, 179, 255);
    border-radius: 6px 6px 0 0;
    height: 35px;
    color: rgb(50, 51, 51);
    cursor: move;
    font-weight: 500;
}

.hak-form {
    padding: 2.5em;
    width: 80%;
}

.form-control {
    width: 85% !important;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn.ok, .btn.cancel {
    background: #dfdfde;
    color: #3c3c40;
    width: 100px;
    margin: 20px 8px 0 8px;
    border-color: transparent;
    font-size: 16px;
}

.btn.ok:hover, .btn.cancel:hover {
    background: #dfdfde;
    color: #3c3c40;
    /* width: 100px; */
    border-color: transparent;
}

.btn-primary:hover {
    background-color: none !important;
}

.hak-modal-settings {
    position: absolute;
    top: 30%;
    left: 35%;
    right: 35%;
    background-color: rgb(158, 165, 173);
    border: 4px solid rgb(69, 76, 82);
}

.hak-settings-inside {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hak-settings-inside-horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.error-message {
    color: #f3ec3b;
    padding-top: 10px;
}

.hak-it-password-container {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.hak-it-password-inputBox {
    padding: 12px 20px;
    margin: 4px 4px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    width: 35px !important;
}
.hak-modal-video {
    position: absolute;
    top: 10%;
    left: 15%;
    right: 15%;
   
}
.pdf img, .expand-block .image img {
    width: 100%;
    height: 100%;
}

.btn-block {
    text-align: center;
}

.ic-close {
    top: 10px !important;
    right: 10px !important;
}

.form-control:focus {
    box-shadow: none !important;
    border: none !important;
}


@media screen and (max-width : 480px){
    .hak-modal-video {
        left: 0%;
        right: 0%;  
    }
}

@media screen and (max-width : 600px){
    .hak-modal-password {
        width: 100%;
        height: 100vh;
    }
    .row {
        /* padding-top: 35%; */
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .hak-modal-header {
        height: 3rem;
        top: 10px;
        right: 10px;
        border-radius: 0 !important;
    }
    .btn-block {
        display: grid !important;
    }
    .btn.ok, .btn.cancel {
        width: 150px;
        font-weight: 600;
    }
    .hak-form {
        padding: 10em 1em !important;
        
    }
    .ic-close {
        top: 10px !important;
        right: 10px !important;
    }
    .hak-modal {
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .hak-modal-pdf{
        bottom:6.5%
    }
}