.hak-icon {
    display: flex;
    height: 125px;
    padding: 0px;
}

.hak-icon-label {
    text-align: center;
    word-wrap: break-word;
}

.hak-icon img {
    margin: 0 auto;
    max-height: 100%;
    height: 100%;
    margin-bottom: 10px;
    cursor: pointer;
}

.hak-icon-container {
    color: white;
}

.hak-icon-ul{
    margin-right: 0px !important;
}

.hak-settings {
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: 1em 1em !important;
    width: 100px;
    position: fixed;
    left: 0;
    color: #fff;
    background-color: transparent !important; 
    border-color: rgb(7,127,124) !important;
    cursor: pointer;
    z-index: 99;
}

.hak-poweroff {
    border-radius: 15px !important;
}

.pdf div[alt="Wedding Vision Board"]{
    margin-right : 15px;
}

li.folder.customize-icon:first-child, li.folder.customize-icon:nth-child(2) {
    display: inline-block;
}

li.folder.customize-icon:nth-child(2) {
    margin: 0 4rem;
}

li.contact.customize-icon:nth-last-child(2) {
    position: absolute;
    right: 4vh;
    bottom: 7vh;
}

li.image.customize-icon:nth-last-child(3) {
    margin: 1.5rem 0;
}

.child-block {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.expand-block li.image.customize-icon:nth-last-child(3) {
    margin: 0 1rem !important;
}

.expand-block ul {
    margin: 1rem 2rem;
}

.note.customize-icon, .image.customize-icon, .pdf.customize-icon  { 
    display: inline-block;
    margin: 0 1rem;
}

.note.customize-icon .desktop-icon {
    width: 6rem;
    height: 6rem;
    margin: 1.5rem 0;
    cursor: pointer;
}

.note.customize-icon img {
    width: 100%;    
    height: 100%;
}

.image.customize-icon {
    margin: 0 2rem;
}

.expand-block .image.customize-icon {
    margin: 0 1rem;
}
